body {
  .ant-popover-inner {
    background-color: var(--white);
    border-radius: 0.42rem;
    box-shadow:
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  }

  .ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 8px 16px;
    border-bottom: 1px solid var(--light3);
    font-weight: 500;
    font-size: 1.275rem;
    color: var(--dark);
    line-height: 1.2;
  }

  .ant-popover-inner-content {
    padding: 12px 16px;
    color: var(--dark);

    ul {
      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
